<template>
	<main>
		<!-- breadcrumb-area -->
		<section>
			<img src="assets/img/bg/tradbazix.jpg" class="img-fluid" />
		</section>

		<section class="about-area pb-60 pt-100">
			<div class="container">
				<!-- START TABS DIV -->
				<div class="tabbable-responsive">
					<div class="tabbable">
						<ul class="nav nav-tabs" id="myTab" role="tablist">
							<li class="nav-item">
								<a
									class="nav-link active"
									id="first-tab"
									data-toggle="tab"
									href="#first"
									role="tab"
									aria-controls="first"
									aria-selected="true"
									>Features</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									id="second-tab"
									data-toggle="tab"
									href="#second"
									role="tab"
									aria-controls="second"
									aria-selected="false"
									>Integrations</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									id="third-tab"
									data-toggle="tab"
									href="#third"
									role="tab"
									aria-controls="third"
									aria-selected="false"
									>Live Demo</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									id="fourth-tab"
									data-toggle="tab"
									href="#fourth"
									role="tab"
									aria-controls="fourth"
									aria-selected="false"
									>Customers</a
								>
							</li>
							<li class="nav-item">
								<a
									class="nav-link"
									id="fifth-tab"
									data-toggle="tab"
									href="#fifth"
									role="tab"
									aria-controls="fifth"
									aria-selected="false"
									>Pricing</a
								>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!--end of tab header-->

			<div class="container">
				<div class="tab-content">
					<div
						class="tab-pane fade show active"
						id="first"
						role="tabpanel"
						aria-labelledby="first-tab"
					>
						<div class="content_wrap pt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Contacts Management</h4>
										</div>
										<p><b>Stay fully connected and master your contacts.</b></p>
										<div class="about-content pl-30 pt-10">
											<ul class="feature-content">
												<li>
													Create and manage contact details for Buyers,
													Suppliers, commission agent etc.
												</li>
												<li>
													Optionally send event driven SMS (Short Message
													Service) automatically; i.e. TradeBazix can be
													configured to automatically send SMS to respective
													contact on events such as billing, payment receipt,
													delivery etc.
												</li>
												<li>
													Merge duplicate contacts.
												</li>
											</ul>
										</div>
									</div>
									<div class="col-lg-6 col-xs-12">
										<img
											src="assets/img/products/contactmanagement.jpg"
											alt="Contact Management"
											class="img-fluid"
										/>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-lg-6 col-xs-12">
										<img
											src="assets/img/products/predefinelist.jpg"
											alt="Pre Defined List"
											class="img-fluid"
										/>
									</div>
									<div class="col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Predefined Lists</h4>
										</div>
										<div class="about-content pt-10">
											<p>
												<strong
													>TradeBazix’s predefined lists help you create sales
													and purchase vouchers in a flash. This time saving
													option is simply great.</strong
												>
											</p>

											<ul class="feature-content pl-30 pt-30">
												<li>
													Create and manage sales or purchase lists for each and
													every buyer or supplier.
												</li>
												<li>
													Import lists in sales or purchase vouchers to just
													finish it off in a matter of seconds.
												</li>
												<li>
													Focus on what really matters and rest is all done
													automatically.
												</li>
											</ul>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Track inventory</h4>
										</div>
										<div class="about-content pt-10">
											<p>
												<b
													>Track inventory and manage goods as they come in and
													go out.</b
												>
											</p>

											<ul class="feature-content pl-30 pt-30">
												<li>
													Categorize stock items to classify stock accounting.
												</li>
												<li>
													Allocate part nos. or barcodes to locate stock items
													easily.
												</li>
												<li>
													Get notified on what needs to be ordered.
												</li>
												<li>
													Create delivery notes and receipt notes.
												</li>
												<li>
													Manage Sales and Purchase returns.
												</li>
												<li>
													Track inventory with multiple units of measurement.
												</li>
												<li>
													Move inventory to the right place at the right time
													and at the right cost.
												</li>
											</ul>
										</div>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12 text-right">
										<img
											src="assets/img/products/trackinventory.jpg"
											alt="Track Inventory"
											class="img-fluid"
										/>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12 text-left">
										<img
											src="assets/img/products/invoice.jpg"
											alt="SalesInvoice"
											class="img-fluid"
										/>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Generate professional sales invoices</h4>
										</div>
										<p>
											<b
												>TradeBazix is completely integrated, which means data
												is entered once and instantly available in all modules
												throughout the system. Once a delivery note has been
												priced, it can be invoiced.</b
											>
										</p>
										<div class="about-content pt-10">
											<ul class="feature-content pl-30">
												<li>
													Multiple options to import delivery notes or create an
													invoice right from the scratch.
												</li>
												<li>
													Create sales invoice with freight charges, labour
													charges etc.
												</li>
												<li>
													Option to directly allocate commission to commission
													agents on total sales or per item basis.
												</li>
												<li>
													Option to manage separate unit of measurement for
													billing and inventory tracking.
												</li>
												<li>
													Automated calculation of duties and taxes and their
													allocation to relevant ledgers.
												</li>
												<li>
													Automatically generate packing lists from sales
													vouchers.
												</li>
											</ul>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Customize it to fit your business</h4>
										</div>
										<div class="about-content pt-10">
											<p>
												<b
													>Whether you are a wholesaler or retailer or run a
													super market or operate a petrol pump, TradeBazix is
													the right solution for you.</b
												>
											</p>

											<ul class="feature-content pt-30 pl-30">
												<li>
													Easily customizable to fit your business.
												</li>
												<li>
													Get reports relevant to your specific business.
												</li>
												<li>
													Core accounting remains the same, rest all can be
													customized as per your needs.
												</li>
											</ul>
										</div>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12 text-right">
										<img
											src="assets/img/products/customize.jpg"
											alt="Customize it to fit your business"
											class="img-fluid"
										/>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12 text-left">
										<img
											src="assets/img/products/financialaccounting.jpg"
											alt="Financial Accounting"
											class="img-fluid"
										/>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Financial Accounting</h4>
										</div>
										<div class="about-content pt-10">
											<p>
												<b
													>TradeBazix covers all the accounting and financial
													requirements of your business. You will love ability
													to drill into original transaction whenever it occurs
													without having to navigate in and out of modules</b
												>
											</p>

											<ul class="feature-content pt-30 pl-30">
												<li>
													Manage receipts, payments, journals and contras.
												</li>
												<li>
													Manage debit notes and credit notes.
												</li>
												<li>
													Manage cash and fund flow.
												</li>
												<li>
													Manage bills receivables and bills payables.
												</li>
												<li>
													Bank reconciliation.
												</li>
												<li>
													Voucher Printing
												</li>
												<li>
													**Cheque Printing
												</li>
											</ul>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Powerful reporting</h4>
										</div>
										<div class="about-content pt-10">
											<p>
												<b
													>The TradeBazix reporting suite includes wide range of
													reports that help you set real time goals and track
													the right metrics. It helps you assess, compare,
													summarize and scrutinize performance and gives you a
													quick view of the most critical data.</b
												>
											</p>

											<ul class="feature-content pt-30 pl-30">
												<li>
													Reports on Sales Invoices, Purchase Invoices,
													Receipts, Payments, Journals and Contras.
												</li>
												<li>
													Day Book, Cash/Bank Book, Ledger Book, Ledger Book
													(Bill-wise) and Ledger Vouchers.
												</li>
												<li>
													Purchase and Sales bills pending.
												</li>
												<li>
													Outstanding receivables and payables.
												</li>
												<li>
													Balance Sheet
												</li>
												<li>
													Profit & Loss account
												</li>
												<li>
													Trial Balance
												</li>
												<li>
													Outstanding statements
												</li>
												<li>
													VAT and VAT classification reports
												</li>
												<li>
													Reports on Stock Summary, Movement and vouchers.
												</li>
												<li>
													Stock Inward and Outward Registers.
												</li>
											</ul>
										</div>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12 text-right">
										<img
											src="assets/img/products/reporting.jpg"
											alt="Powerful reporting"
											class="img-fluid"
										/>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6 col-xs-12 text-left">
										<img
											src="assets/img/products/secure.jpg"
											alt="Secure and accessible"
											class="img-fluid"
										/>
									</div>
									<div class="col-xl-6 col-lg-6 col-xs-12">
										<div class="section-title pb-15">
											<h4>Secure and accessible</h4>
										</div>
										<div class="about-content pt-10">
											<ul class="feature-content pl-30">
												<li>
													Role based profiles can be used to secure and simplify
													process, features and functions for each group of
													employees.
												</li>
												<li>
													<b>Keep an eye on who did what?</b>
													<ul class="feature-content pl-30 pt-10">
														<li>
															System automatically saves information such as who
															created, modified or accessed which entry, at what
															time and from which computer.
														</li>
														<li>
															You can also track which entry was deleted by
															whom, at what time and from which computer.
														</li>
													</ul>
												</li>
												<li>
													**Online connectivity provides real time low cost
													access to information across all parts of your
													business.
												</li>
											</ul>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div
							class="content_wrap mt-40"
							style="background-image:url(assets/img/bg/footer-bg-4.jpg);background-repeat: no-repeat;background-size: cover;"
						>
							<section class="about-area speial-feature">
								<div class="row">
									<div class="col-xl-12 col-lg-12 col-xs-12">
										<div class="section-title pb-5">
											<h3>Special Features</h3>
										</div>
										<div class="about-content">
											<p>
												<i class="fa fa-check pr-10" aria-hidden="true"></i
												>Fully customizable to meet your requirement
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Multi company generation
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Multi user
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>SMS integration
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Option for multi site online synchronization
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Option for multi site offline synchronization
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Voucher and Report level user based security
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Backup and Restore companies
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Tally Integration
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Directly open vouchers from reports
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Print, Email or Export vouchers and reports
											</p>

											<p>
												<i
													class="fa fa-check pr-10 pt-15"
													aria-hidden="true"
												></i
												>Email or Export vouchers and reports in various file
												formats such as PDF, Doc, Docx, Xls, Xlsx etc.
											</p>
										</div>
									</div>
								</div>
							</section>
						</div>

						<div class="content_wrap mt-40">
							<div class="servicee-sidebar mb-50">
								<div class="sidebar-link grey-bg">
									<h3>Excellent Software at Fair Prices</h3>
									<div class="about-content pt-10" style="padding: 25px;">
										<h6>
											<b
												>You pay only once for permanent use of your license!</b
											>
										</h6>

										<P
											><i
												class="fa fa-check-square-o pr-10 pt-30"
												aria-hidden="true"
											></i
											>TradeBazix combines all the features required for a
											complete fleet management application in a single
											software.</P
										>

										<P
											><i
												class="fa fa-check-square-o pr-10 pt-15"
												aria-hidden="true"
											></i
											>And the best of all: A single purchase with permanent use
											and no update requirements</P
										>
										<br />

										<h5 class="pt-15">Investment Security</h5>
										<p>
											<i class="fa fa-check pr-10 pt-30" aria-hidden="true"></i
											>Lifetime licenses – use is possible without time
											restrictions.
										</p>

										<p>
											<i class="fa fa-check pr-10 pt-15" aria-hidden="true"></i
											>1 year free customer support.
										</p>

										<p>
											<i class="fa fa-check pr-10 pt-15" aria-hidden="true"></i
											>1 month free for customization.
										</p>

										<p>
											<i class="fa fa-check pr-10 pt-15" aria-hidden="true"></i
											>Telephonic and online consultation or support via
											competent employees.
										</p>

										<p>
											<i class="fa fa-check pr-10 pt-15" aria-hidden="true"></i
											>Easy and affordable post-warranty subscriptions
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--end of tab 1-->

					<div
						class="tab-pane fade"
						id="second"
						role="tabpanel"
						aria-labelledby="second-tab"
					>
						<h6><b>TradeBazix works seemlessly with following services</b></h6>
						<br />
						<p>
							<i class="fa fa-check pr-10" aria-hidden="true"></i
							><b>Tally accounting software</b>
						</p>
						<p>
							Import data from tally accounting software or export to the same
						</p>

						<p>
							<i class="fa fa-check pr-10" aria-hidden="true"></i
							><b>Microsoft Office</b>
						</p>
						<p>
							Import data from excel worksheet or export to word, excel, outlook
							express etc.
						</p>

						<p>
							<i class="fa fa-check pr-10" aria-hidden="true"></i
							><b>Acrobat Reader</b>
						</p>
						<p>Export data from TradeBazix to create PDF files.</p>
					</div>
					<!--end of tab 2-->
					<div
						class="tab-pane fade"
						id="third"
						role="tabpanel"
						aria-labelledby="third-tab"
					>
						<p>
							If you want us to show you what our software can do for you, Tell
							us something about you and we will gladly arrange a live demo for
							you
						</p>
						<br />
						<div class="row">
							<div class="col-md-7">
								<form
									id="contact-form"
									action="#"
									class="contact-form"
									method="POST"
								>
									<div class="row">
										<div class="col-xl-6 col-lg-6 mb-20">
											<input name="name" type="text" placeholder="Name" />
										</div>
									</div>
									<div class="row">
										<div class="col-xl-6 col-lg-6 mb-20">
											<input name="email" type="email" placeholder="Email" />
										</div>
									</div>
									<div class="row">
										<div class="col-xl-6 col-lg-6 mb-20">
											<input
												name="contact"
												type="contact"
												placeholder="Contact No"
											/>
										</div>
									</div>
									<div class="row">
										<div class="col-xl-6 col-lg-6 mb-20">
											<button class="btn" type="submit">Submit</button>
										</div>
									</div>
								</form>
								<p class="ajax-response"></p>
							</div>
						</div>
					</div>
					<!--end of tab 3-->
					<div
						class="tab-pane fade"
						id="fourth"
						role="tabpanel"
						aria-labelledby="fourth-tab"
					>
						<div class="content_wrap mt-10">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-12 col-lg-12">
										<section class="testimonial-area pt-100 pb-100">
											<div class="container">
												<div class="row">
													<div class="col-xl-12">
														<div class="testimonial-active owl-carousel">
															<div
																class="single-item testimonial-items text-center"
															>
																<div class="client-text">
																	We love TradeBazix for its simplicity. It is
																	as simple as 1-2-3. Yet we get a lot out of it
																	and that's the beuty of this software
																</div>
																<h4>Shree Ambica Automobiles, Gandhidham</h4>
															</div>
															<div
																class="single-item testimonial-items text-center"
															>
																<div class="client-text">
																	We found this software a highly configurable,
																	easy yet powerful. We are having a petrol pump
																	and besides all the goodies that one can think
																	of in an accounting and inventory software,
																	TradeBazix helps us take dip measurements,
																	meter readings and get a variation report on
																	sales from respected measurements.
																</div>
																<h4>Om Petroleum, Sayla</h4>
															</div>
															<div
																class="single-item testimonial-items text-center"
															>
																<div class="client-text">
																	We deal in jaggery and coconut in wholesale.
																	We have one measurement for rates when we
																	purchase and other while we sale them, add to
																	that, we maintain stock on other unit of
																	measurement, and all these have been possible
																	just because of TradeBazix
																</div>
																<h4>Ambica Trading, Gandhidham</h4>
															</div>
															<div
																class="single-item testimonial-items text-center"
															>
																<div class="client-text">
																	We need to deal in thousands of products, each
																	having different parameters and
																	characteristics. It was a nightmare to take
																	account of all these, But TradeBazix have made
																	these all possible, we really love it.
																</div>
																<h4>Krishna Electricals, Ra</h4>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div>
								</div>
							</section>
						</div>
					</div>
					<!--end of tab 4-->
					<div
						class="tab-pane fade"
						id="fifth"
						role="tabpanel"
						aria-labelledby="fifth-tab"
					>
						<div class="content_wrap">
							<section class="about-area">
								<div class="row">
									<div class="col-xl-6 col-lg-6">
										<h6><b>Our prizing includes free</b></h6>
										<br />
										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i
											>Personalized Training
										</p>

										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i
											>Customization for the first month of purchase
										</p>

										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i
											>Telephonic/Remote Support for the first year of purchase
										</p>
									</div>
									<div class="col-xl-6 col-lg-6">
										<h6><b>Ask for a Free Trial</b></h6>
										<br />
										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i>Get
											satisfied before buying
										</p>

										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i>Trial
											includes all the features mentioned herein
										</p>

										<p>
											<i class="fa fa-check pr-10" aria-hidden="true"></i>You
											can retain your data and customization from trial at the
											time of purchase
										</p>
									</div>
								</div>

								<div class="row pt-30">
									<div class="col-xl-12 col-lg-12">
										<table class="table table-bordered table-striped">
											<tr>
												<td>Particulars</td>
												<td>Amount (INR)</td>
											</tr>

											<tr>
												<td>
													<b>Single user</b><br /><br />(for lifetime use)
												</td>
												<td><b>18000/-</b> (per license)</td>
											</tr>

											<tr>
												<td>
													<b>Add-on workstations (local)</b><br /><br />(for
													lifetime use)
												</td>
												<td><b>2000/-</b> (per license)</td>
											</tr>

											<tr>
												<td>
													<b>Add-on workstations (remote)</b><br /><br />(for
													lifetime use)
												</td>
												<td><b>5000/-</b> (per license)</td>
											</tr>
										</table>
									</div>
								</div>

								<div class="row pt-30">
									<div class="col-lg-12 col-md-12">
										<div class="section-title pb-15">
											<h5>Frequently asked Questions</h5>
										</div>
										<p><b>Q - How can I pay ?</b></p>
										<p>
											We are going to accept online payments very soon, for the
											mean time you can pay through a high value cheque or
											demand draft in favor of Extreme Solutions and payable at
											Gandhidham.
										</p>
										<p>
											You can also use RTGS or NEFT modes of payment, if you
											wish to.
										</p>
									</div>
								</div>

								<div class="row pt-30">
									<div class="col-lg-6 col-md-6 col-xs-12">
										<p><b>Q - Will I have to pay renewal charges ?</b></p>
										<p>No, our prizing are for lifetime use</p>

										<p><b>Q - What is included in the training ?</b></p>
										<p>
											A comprehensive and gradual training program will be held
											by our experienced team member to get you on board.
										</p>
										<p>
											You will get help with each and every module and will also
											get to know some hidden features as you get along.
										</p>

										<p><b>Q - What customization</b></p>
										<p>
											Anything, that you find you need it your way, you can ask.
											Most of the customization for the first month of purchase
											will remain free.
										</p>

										<p>
											<b
												>Q - Can I request customization after first month of
												purchase ?</b
											>
										</p>
										<p>
											Yes, you can ask for customization at anytime, however, it
											will be at some prize after the first month of purchase.
										</p>
									</div>
									<div class="col-lg-6 col-md-6 col-xs-12">
										<p>
											<b
												>Q - What will be the charges for customization after
												first month of purchase ?</b
											>
										</p>
										<p>
											It depends on what kind of customization you are asking
											for. We will let you know estimated time and cost for the
											customization at the time of your request.
										</p>

										<p><b>Q - What is telephonic/remote support ?</b></p>
										<p>
											In case if you get stuck somewhere, You will be personally
											attended by one of our team member to guide you on
											telephone or assist you through remote desktop software.
										</p>

										<p>
											<b
												>Q - Will I get support after first year of purchase
												?</b
											>
										</p>
										<p>Yes, but at a prize.</p>

										<p>
											<b
												>Q - What will it cost for telephonic/remote support
												after first year of purchase ?</b
											>
										</p>
										<p>
											Our subscriptions are simple and easy to understand. You
											can buy our annual support contract and enjoy support for
											a year or you can buy support tickets (minimum 10), which
											can be used without any time restrictions.
										</p>
									</div>
								</div>
							</section>
						</div>
					</div>
					<!--end of tab 5-->
				</div>
			</div>
			<!--end of tab content-->
		</section>
	</main>
</template>

<script>
export default {
	name: "tradebazix",
	mounted() {
		// script for testimonials slider
		$(".testimonial-active").owlCarousel({
			loop: true,
			margin: 10,
			nav: false,
			dots: false,
			autoplay: true,
			responsive: {
				100: {
					items: 1,
				},
				200: {
					items: 1,
				},
				300: {
					items: 1,
				},
			},
		});
	},
};
</script>

<style></style>
